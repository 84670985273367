* {
  font-family: brandon-grotesque, sans-serif;
  color: #2f2f2f;
}

body {
  margin: 0;
  background: #f8f9fb;
}

a {
  text-decoration: none;
}

.slick-arrow {
  cursor: pointer;
  padding: 3px 10px;
  border: 1px solid;
  position: absolute;
  top: 0;
}

.slick-next {
  right: 0;
}

.slick-slider {
  padding-top: 50px;
}

.widthAuto {
  width: auto !important;
  padding-left: 10px;
  padding-right: 10px;
}

.role.attributes {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  .role-div {
    width: 22%;
    margin: 5px !important;
  }
}

h1 {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 18px;
  vertical-align: middle;
}

hr {
  height: 1px;
  background-color: #e2e2e2;
  border: none;
}

p {
  margin: 0;
}

input,
textarea {
  font-size: 14px;
  border: none;
  background: #f2f2f2;
  border-radius: 5px;
  padding-left: 15px;
  height: 40px;
  width: calc(100% - 15px);
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: #2f2f2f;
}

textarea {
  height: 50%;
  padding: 15px 0 0 15px;
}

textarea.narrow {
  height: 100px;
}

.ab {
  position: absolute;
}

.reltv {
  position: relative;
}

.type-popup {
  position: fixed;
  bottom: 90px;
  right: 90px;
  width: 160px;
  height: 70px;
  background: #ffffff;
  border-radius: 10px;
  z-index: 20;
  padding: 20px;
}

.type-popup:after,
.type-popup:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.type-popup:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #ffffff;
  border-width: 15px;
  margin-top: -15px;
}

.type-popup:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #ffffff;
  border-width: 15px;
  margin-top: -15px;
}

.total-count {
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 21px;
  font-size: 11px;
  width: 90px !important;
}

.period {
  width: 21%;
}

.image-close {
  position: absolute;
  left: 50px;
  top: 10px;
  width: 20px;
  height: 20px;
  z-index: 30;
}

.image-circle {
  margin-right: 13px;
  .img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .img.big {
    width: 80px;
    height: 80px;
  }
  .img.huge {
    width: 132px;
    height: 132px;
  }
  .img.small {
    width: 20px;
    height: 20px;
  }
  .img.small.overlap {
    margin-right: -16px;
  }
}

.image-circle.big {
  width: 80px;
  height: 80px;
}

.image-circle.huge {
  width: 132px;
  height: 132px;
  margin-bottom: 15px;
}

.image-square {
  margin-top: 10px;
  .img {
    width: 100%;
  }
  .image-close {
    top: 10px;
    left: 230px;
  }
}

.image-overlay.round {
  border-radius: 50%;
}

.upload-div {
  background: #f2f2f2;
  border-radius: 50%;
  width: 80px;
  height: 60px;
  padding-top: 20px;
  .img {
    width: 40px;
    height: 40px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  z-index: -1;
}

.overlay.active {
  z-index: 10;
  background: black;
}

.image-overlay {
  background: black;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.1;
  z-index: 21;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.inline {
  display: inline-block;
}

.display-none {
  display: none;
}

.user-logo {
  border-radius: 50%;
  background: #e2e2e2;
  color: #707070;
  font-weight: 300;
  text-align: center;
}

.center {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.arrow {
  height: 10px;
  width: 5px;
}

.arrow-left {
  padding-top: 5px;
  margin-right: 10px;
}

.arrow-left.big {
  height: 30px;
  width: 30px;
  margin-left: 30px;
  margin-top: 30px;
}

.arrow-left.big.activity {
  height: 20px;
  width: 20px;
  margin-left: 30px;
  margin-top: 30px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.arrow-div {
  width: 5px;
  text-align: right;
  vertical-align: middle;
}

.with-arrow {
  label {
    height: 10px;
  }
  > div:first-of-type {
    width: calc(100% - 5px);
  }
}

.clickable {
  cursor: pointer;
}

.round-checkbox {
  width: 15px;
  height: 15px;
  background: #f2f2f2;
  border-radius: 50%;
  div {
    margin: 3px 3px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }
  div.active {
    background: #42aee3;
  }
}

.confirmation-success {
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
}

.squre-checkbox {
  width: 15px;
  height: 15px;
  background: #f2f2f2;
  img {
    margin-left: 2px;
    margin-top: 3px;
    display: none;
  }
  .active img {
    display: block;
  }
}

.search-field {
  position: relative;
  img {
    position: absolute;
    right: 18px;
    top: 12px;
  }
}

.normal-margin {
  margin: 28px 30px;
}

.normal-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px #e2e2e2;
}

.normal-box-margin {
  margin-left: 20px;
}

.normal-box-margin-1 {
  margin-left: 160px;
}

.normal-box-margin-2 {
  margin-left: 660px;
}

.styles_overlay__CLSq- {
  background: rgba(0, 0, 0, 0.09);
}

.styles_modal__gNwvD {
  box-shadow: none;
  height: 100px;
  width: 290px;
  border-radius: 10px;
  padding: 30px 0;
  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
}

.red-white-button {
  position: absolute;
  bottom: 30px;
  left: 28px;
  width: calc(100% - 60px);
  border: 1px solid #a9263d;
  height: 40px;
  border-radius: 5px;
  color: #a9263d;
  font-size: 13px;
  font-weight: bold;
  background: #ffffff;
}

.white-blue-button {
  width: 100%;
  margin-bottom: 10px;
  background: #42aee3 0% 0% no-repeat padding-box;
  height: 30px;
  text-align: center;
  letter-spacing: 0;
  line-height: 30px;
  color: #ffffff;
  border-radius: 5px;
}

.blue-white-button {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 30px;
  text-align: center;
  letter-spacing: 0;
  line-height: 30px;
  color: #42aee3;
  border-radius: 5px;
  border: 1px solid #42aee3;
}

.red-white-button.not-ab {
  position: inherit;
  width: 100%;
  margin-top: 20px;
}

.white-red-button {
  width: 100%;
  height: 30px;
  background: #a9263d;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}

.white-red-button.session {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
  margin-bottom: 90px;
}

.error-div {
  border: 1px solid #a9263d;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  color: #a9263d;
  .error-img-div {
    text-align: left;
  }
}

.error-img-div {
  color: #a9263d;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  margin: 5px 0;
  text-align: right;
  .error-icon {
    margin-right: 10px;
    vertical-align: -20%;
  }
}

.userModal {
  button {
    height: 40px;
    width: 90px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
  }
  .yesButton {
    background: #a9263d;
    border: 1px solid #a9263d;
    color: #ffffff;
    margin-right: 10px;
  }
  .cancelButton {
    border: 1px solid #b1b1b1;
    color: #b1b1b1;
  }
}

.login-system {
  .error-div {
    margin: 10px auto;
    width: 250px;
  }
  h1 {
    font-weight: 900;
    font-size: 26px;
    text-align: center;
    color: #a9263d;
    margin: 0;
    line-height: 43px;
  }
  h5 {
    margin: 0;
    font-weight: normal;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    line-height: 34px;
  }
  input {
    width: 276px;
    margin-top: 21px;
    height: 60px;
    border-radius: 30px;
    background: #f2f2f2;
    box-shadow: 0px 0px 10px rgba(169, 38, 61, 0);
    padding-left: 25px;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }
  input::placeholder {
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }
  button {
    width: 150px;
    height: 50px;
    border-radius: 30px;
    background: #a9263d;
    box-shadow: 0px 0px 10px rgba(169, 38, 61, 0);
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #fff;
    padding: 0;
    margin: 0;
  }
  .button-div {
    margin-top: 19px;
    height: 50px;
    a {
      margin-top: 19px;
    }
    button {
      float: right;
    }
  }
  .red-background {
    h1 {
      color: #fff;
    }
    h5 {
      color: #fff;
    }
  }
  .sign-in-container {
    padding: 30px;
  }
  .sign-in-box {
    width: 300px;
    margin: 20px auto;
  }
  .copyright {
    position: fixed;
    left: 20px;
    bottom: 20px;
    font-weight: normal;
    font-size: 12px;
  }
  .left {
    float: left;
    width: 60%;
    height: 100vh;
    position: relative;
  }
  .right {
    background: linear-gradient(#a9263d 0%, #892637 100%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    float: left;
    width: 40%;
    height: 100vh;
    padding-top: calc(50vh - 160px);
    text-align: center;
    button {
      margin-top: 30px;
      width: 200px;
      height: 60px;
    }
  }
  .logo {
    margin: 30px 0 0 30px;
  }
}

.Signup,
.ForgetPassword {
  .copyright {
    color: #ffffff;
  }
  .button-div {
    margin-top: 30px;
  }
  .postive-vote {
    width: 108px;
    height: 108px;
    margin-top: 36px;
    margin-bottom: 20px;
  }
  p {
    padding: 0 60px;
    font-weight: normal;
    font-size: 21px;
    text-align: center;
  }
}

.ForgetPassword {
  .sign-in-box {
    text-align: center;
    width: 400px;
    margin: calc(50vh - 180px) auto;
  }
  .button-div {
    margin-top: 30px;
    text-align: center;
    button {
      float: none;
      margin: auto;
    }
  }
}

.App {
  padding: 10px 30px;
}

.Nav {
  width: 200px;
  float: left;
  a {
    position: relative;
  }
  h1 {
    font-weight: bold;
    font-size: 21px;
    text-align: left;
    margin: 0;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
    margin-bottom: 38px;
  }
  .nav-item {
    height: 20px;
    width: 160px;
    margin-bottom: 10px;
    padding: 13px 20px 12px 20px;
    font-weight: 300;
    font-size: 14px;
    text-align: left;
  }
  .active,
  .nav-item:hover {
    background: #ffffff;
    border-radius: 33px;
    font-weight: bold;
  }
  .nav-red-dot {
    position: absolute;
    left: 160px;
    top: 10px;
    z-index: 2;
  }
  .user-logo {
    margin-top: 27px;
    height: 55px;
    width: 70px;
    padding-top: 15px;
    font-size: 30px;
  }
  .user-logo-div {
    position: relative;
    margin-bottom: 8px;
    .nav-red-dot {
      position: absolute;
      left: 60px;
      top: 25px;
    }
  }
}

.nav-red-dot {
  background: #a9263d;
  width: 20px;
  height: 12px;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  padding: 6px 2px;
}

.field-group {
  margin-top: 20px;
  .field {
    margin-top: 10px;
    * {
      font-weight: normal;
      font-size: 15px;
      text-align: left;
      vertical-align: middle;
    }
    label {
      span {
        font-weight: bold;
      }
    }
    .role-div {
      height: 30px;
      text-align: center;
      font-weight: normal;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      color: #b1b1b1;
      border-radius: 5px;
      border: 1px solid #b1b1b1;
      cursor: pointer;
    }
    .role-div.active {
      background: #42aee3;
      border: #42aee3;
      color: #ffffff;
    }
    .role-div:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .with-arrow {
    cursor: pointer;
    label {
      height: 10px;
    }
  }
  .user-logo {
    padding-top: 4px;
    width: 28px;
    height: 24px;
    text-align: center;
    margin-top: 8px;
    font-size: 15px;
    font-weight: 100;
    border: 2px solid #f4f4f4;
    margin-left: -12px;
  }
  .user-logo:first-child {
    margin-left: 0px;
  }
  .more {
    margin-top: 8px;
  }
}

.select-div,
.manage-div {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  margin-bottom: 20px;
  .item-name {
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #707070;
    height: 40px;
    margin-top: 17px;
    span {
      line-height: 20px;
      vertical-align: middle;
      max-width: 140px;
    }
    .image-circle {
      height: 40px;
    }
    .user-logo {
      margin-left: 0px;
      margin-right: 5px;
      height: 40px !important;
      width: 40px !important;
      line-height: 40px !important;
      vertical-align: middle;
      font-size: 13px !important;
    }
    .img {
      height: 40px;
      width: 40px;
      vertical-align: middle;
    }
    .image-circle {
      margin-right: 5px;
    }
    .activity.squre-checkbox,
    .activity.round-checkbox {
      margin: 13px 3px;
    }
    .colordot {
      margin-left: 5px;
      height: 5px;
      width: 5px;
      background: #7abb00 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #7abb0073;
      border-radius: 50%;
    }
    .colordot.red {
      background: #a9263d 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #a9263d72;
    }
    .colordot.amber {
      background: #ffd800 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #ffd800;
    }
  }
}

.manage-list {
  z-index: 1;
}

.select-div {
  z-index: 20;
  .clickable {
    margin-top: 28px;
  }
}

.step-div {
  position: fixed;
  width: 140px;
  background: #ffffff;
  box-shadow: 0px 0px 10px #e2e2e2;
  border-radius: 10px;
  .step-container {
    margin: 10px;
    height: 80px;
    .step-round {
      border-radius: 50%;
      height: 10px;
      width: 10px;
      border: 1px solid #2f2f2f;
      opacity: 0.3;
      margin: auto;
      margin-top: 40px;
    }
    h1 {
      margin-top: 12px;
      text-align: center;
      opacity: 0.3;
      font: Bold 14px/20px;
      letter-spacing: 0;
    }
  }
  .step-container.done {
    .step-round {
      height: 24px;
      width: 24px;
      border: none;
      opacity: 1;
      background: #a9263d;
      text-align: center;
      vertical-align: middle;
    }
    h1 {
      color: #a9263d;
      opacity: 1;
    }
  }
  .step-container.editing {
    .step-round {
      height: 24px;
      width: 24px;
      border: none;
      opacity: 1;
      background: #42aee3;
      text-align: center;
      vertical-align: middle;
    }
    h1 {
      color: #42aee3;
      opacity: 1;
    }
  }
  .step-container:not(:last-of-type) {
    border-bottom: 1px solid #e2e2e2;
  }
}

.Main {
  width: 100%;
  .list-div {
    padding-top: 17px;
    padding-bottom: 17px;
    .inline {
      margin-right: 10px;
      vertical-align: middle;
    }
    .user-logo {
      height: 32px;
      width: 40px;
      padding-top: 8px;
      font-size: 18px;
    }
    .name {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      span {
        font-weight: 300;
        font-size: 14px;
      }
    }
    .arrow-div {
      margin-top: 5px;
    }
    .button-div {
      margin-top: 5px;
      button {
        border: none;
        background: transparent;
        z-index: 5;
        cursor: pointer;
      }
    }
  }
  .manage-list {
    h1 {
      padding: 28px 30px 0 30px;
    }
    > a {
      display: block;
      padding: 0 30px;
    }
    a:not(:last-of-type) {
      border-bottom: 1px solid #e2e2e2;
    }
    a.active,
    a:hover {
      background: rgba(66, 174, 227, 0.09);
      .user-logo {
        background: #42aee3;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
  .upsert-one-element-div {
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    min-height: 250px;
    .upsert-one-element {
      padding: 20px;
      h1 {
        color: #707070;
      }
      label {
        width: 110px;
      }
      input {
        width: calc(100% - 126px);
      }
      input.narrow {
        width: calc(100% - 20px);
      }
      input.center {
        padding: 0 8px;
      }
      .colon {
        font-size: 20px;
        line-height: 24px;
      }
      .hour.inline,
      .minute.inline {
        width: calc(50% - 40px);
        border: none;
        text-indent: 45%;
        height: 40px;
        background: #f2f2f2;
      }
      @media only screen and (max-width: 576px) {
        .hour.inline,
        .minute.inline {
          text-indent: 35%;
        }
      }
      @media only screen and (max-width: 420px) {
        .hour.inline,
        .minute.inline {
          text-indent: 5%;
        }
      }
      .am-pm {
        width: 30px;
        height: 40px;
        border: none;
        background: #f2f2f2;
      }
      .hour.inline,
      .colon.inline {
        margin-right: 8px !important;
      }
      .inline.start-time {
        width: 100%;
      }
    }
  }
  .upsert-one-element-div.session {
    .hour.inline,
    .minute.inline {
      width: 39% !important;
    }
    width: 100%;
    min-height: 700px;
  }
  .upsert-one-element-div.goodnewsform {
    z-index: 20;
    textarea {
      margin-left: 5px;
      width: calc(100% - 70px);
      min-height: 100px;
      vertical-align: middle;
      background: #ffffff;
    }
    label {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
      background: #f2f2f2;
      text-align: center;
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      border-radius: 5px 0 0 5px;
    }
    .field {
      border: 1px solid #f4f4f4;
      border-radius: 5px;
    }
    .field-group {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    input {
      width: calc(100% - 100px);
      background: #ffffff;
    }
    .arrow {
      right: 10px;
      top: 17px;
    }
  }
  .upsert-one-element-div.goodnewsform,
  .upsert-one-element-div.goodnews {
    width: 100%;
    min-height: 80px;
    h1 {
      font-size: 14px;
      color: #2f2f2f;
    }
    .user-logo {
      height: 40px;
      width: 50px;
      padding-top: 10px;
      font-size: 21px;
      background: #f4f4f4;
      color: #707070;
      font-weight: 100;
    }
    .user-logo-div {
      position: relative;
      margin-bottom: 8px;
      padding-bottom: 10px;
      border-bottom: 1px solid #b1b1b1;
    }
    .share {
      margin-left: 10px;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0;
      color: #2f2f2f;
      opacity: 1;
      font-weight: 100;
    }
    .photo {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 30px;
      width: 80px;
      background: #f2f2f2 0% 0% no-repeat padding-box;
      border-radius: 20px;
      h1 {
        margin-top: 8px;
        margin-left: 5px;
      }
    }
    .photo-icon {
      margin-left: 10px;
      margin-top: 4px;
    }
  }
  .role.inline.activity {
    width: calc(100% - 110px);
    .role-div {
      width: 47.5%;
    }
  }
  .activity.upsert-one-element-div {
    min-height: 700px;
  }
  .Breadcrumbs {
    height: 20px;
    margin-bottom: 15px;
    .float-left {
      font-weight: bold;
      font-size: 20px;
      text-align: left;
    }
    .float-right {
      font-weight: 300;
      font-size: 14px;
      text-align: right;
      span {
        font-weight: bold;
      }
    }
  }
  .branch-div {
    width: 140px;
    .branch-name {
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 16px;
    }
    .branch-name.active {
      font-weight: bold;
      color: #a9263d;
      span {
        font-weight: bold;
        color: #a9263d;
      }
    }
  }
  .Users {
    .user-list-div {
      width: 480px;
      height: 700px;
      overflow: auto;
    }
    .select-div {
      width: 320px;
    }
    .edit-user-div {
      position: relative;
      width: 320px;
      min-height: 700px;
      .edit-user {
        .field-group {
          .field {
            label {
              width: 32%;
            }
            input {
              width: 61%;
            }
            .role-div {
              width: 42px;
            }
          }
        }
        .user-logo-div {
          margin: 0 auto;
          width: 120px;
        }
        .user-logo {
          height: 120px;
          width: 120px;
          font-size: 18px;
          line-height: 120px;
          font-size: 40px;
        }
      }
    }
  }
  .add-user-div {
    position: relative;
    width: 100%;
    min-height: 700px;
    margin-left: 0;
    .add-user {
      .field-group {
        .field {
          .role-div {
            width: 78px;
          }
          .role-div:not(:last-of-type) {
            margin-right: 5px;
          }
        }
      }
    }
    .postive-vote {
      padding-top: 20px;
    }
    p {
      padding: 22px 80px;
      font-size: 13px;
    }
    .white-red-button {
      width: 140px;
      margin-bottom: 17px;
    }
    .white-red-button.ab {
      position: absolute;
      right: 30px;
      bottom: 20px;
    }
    h1 {
      font-size: 12px;
      color: #a9263d;
      .arrow {
        margin-right: 5px;
      }
    }
  }
  .select-div,
  .manage-div {
    width: 100%;
  }
  .Branches,
  .Activities {
    .white-red-button {
      width: 139px;
      position: absolute;
      right: 30px;
      top: 25px;
    }
  }
  .Dashboard {
    margin-bottom: 50px;
    h2 {
      font-size: 21px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 13px;
    }
    p {
      font-size: 12px;
    }
    p.meta,
    span {
      font-size: 10px;
    }
    a {
      color: #a9263d;
    }
    .header {
      position: relative;
      height: 50px;
    }
    .headerTitle {
      float: left;
      h4 {
        margin: 9px 0 0 0;
      }
    }
    .headerFilter {
      float: right;
      position: relative;
      p {
        display: inline-block;
        color: #2f2f2f;
        margin-left: 25px;
        opacity: 0.8;
        padding-bottom: 5px;
        cursor: pointer;
      }
      p:hover {
        opacity: 1;
        border-bottom: 2px solid #000;
      }
      p.selected {
        opacity: 1;
        font-weight: bold;
        border-bottom: 2px solid #000;
      }
    }
    .customTooltip {
      background-color: #fff;
      box-shadow: 0px 3px 6px 0px #b2b2b2;
      position: relative;
      padding: 11px 0 11px 0;
      width: 110px;
      height: 32px;
      z-index: 1;
      h5 {
        margin: 0;
      }
      .tooltipPart {
        float: left;
        height: 31px;
        padding: 0 11px 0 10px;
        position: relative;
        border-right: 1px solid #b1b1b1;
        width: 33px;
        span {
          position: relative;
          bottom: 7px;
        }
      }
      .tooltipPart:last-child {
        border: none;
        padding: 0 11px 0 10px;
      }
    }
    .myCustomTooltip {
      background-color: #fff;
      box-shadow: 0px 3px 6px 0px #b2b2b2;
      position: absolute;
      padding: 11px 0 11px 0;
      width: 110px;
      height: 32px;
      z-index: 1;
      h5 {
        margin: 0;
      }
      .tooltipPart {
        float: left;
        height: 31px;
        padding: 0 11px 0 10px;
        position: relative;
        border-right: 1px solid #b1b1b1;
        width: 33px;
        span {
          position: relative;
          bottom: 7px;
        }
      }
      .tooltipPart:last-child {
        border: none;
        padding: 0 11px 0 10px;
      }
    }
    .myCustomTooltip:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 5px;
      margin-left: -5px;
    }
    .overview {
      margin-bottom: 14.5px;
      .overviewContainer {
        .overviewBanner {
          max-width: 230px;
          height: 150px;
          width: 100%;
          display: inline-block;
          margin-right: 20px;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0px 0px 15px 0px #e2e2e2;
          .overviewInfo {
            position: relative;
            bottom: -30px;
            padding: 20px;
            h2,
            span,
            p {
              color: #fff;
            }
            h2,
            p {
              margin: 0;
            }
            .overviewNumber {
              position: relative;
              h2 {
                display: inline-block;
                position: relative;
                min-width: 45px;
              }
              span {
                position: absolute;
                top: 3px;
                right: -50px;
              }
            }
          }
        }
        .overviewBanner:last-child {
          margin-right: 0;
        }
      }
    }
    .performance {
      padding: 30px;
      background-color: #fff;
      box-shadow: 0px 0px 15px 0px #e2e2e2;
      border-radius: 10px;
      margin-top: 20.5px;
      min-height: 282.5px;
      h5,
      span {
        margin: 0;
      }
      .chart {
        height: 170px;
        margin-top: 20.5px;
      }
      .monthChartWrapper {
        display: flex;
        position: relative;
        .monthChart {
          height: 170px;
          width: 100%;
          margin: 0 14px 0 0;
          text-align: center;
        }
        .monthChart:last-child {
          margin: 0;
        }
      }
      .backgroundChart {
        position: absolute;
        height: 170px;
        width: 100%;
      }
      .performanceCharts {
        position: relative;
        .timeBars {
          position: relative;
          display: flex;
          h5 {
            position: relative;
            bottom: -6px;
          }
          span {
            position: relative;
            bottom: 2px;
          }
          .weekBar {
            width: 100%;
            margin: 0 14px 0 0;
            background-color: #f2f2f2;
            border-radius: 5px;
            text-align: center;
          }
          .weekBar.month {
            margin: 0 10px 0 0;
          }
          .weekBar:last-child {
            margin: 0;
          }
        }
      }
    }
    .reportCartWrapper {
      display: flex;
      margin-top: 20px;
    }
    .reportCard {
      max-width: 313px;
      width: 100%;
      height: 600px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #e2e2e2;
      margin: 0 20px 0 0;
      position: relative;
      overflow: hidden;
      select {
        appearance: none;
        border: none;
        border-radius: 22px;
        background-color: #f2f2f2;
        padding: 6px 48px 6px 15px;
        height: 30px;
        margin: 9px 0 0 0;
        font-size: 12px;
        position: relative;
      }
      .reportCardContainer {
        padding: 30px;
      }
      .headerFilter:after {
        content: "▾";
        position: absolute;
        right: 15px;
        bottom: 4px;
      }
      .feedWrapper {
        overflow: auto;
        height: 366px;
        box-sizing: border-box;
      }
      .feedWrapper::-webkit-scrollbar {
        width: 1px;
      }
      .feedWrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
        border-radius: 2px;
      }
      .feedWrapper::-webkit-scrollbar-thumb {
        border-radius: 2px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
      }
      .feedContainer {
        border-bottom: 1px solid #b1b1b1;
        margin-bottom: 13.5px;
        padding-bottom: 13.5px;
        .authorProfile {
          .authorPicture {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #f4f4f4;
            float: left;
            margin-right: 10px;
          }
          .authorName {
            p {
              font-weight: 700;
              color: #a9263d;
              margin: 0;
            }
            span {
              position: relative;
              bottom: 7px;
            }
          }
        }
        .feedThumb {
          .feedThumbContainer {
            border-radius: 10px;
            height: 120px;
            background-size: cover;
            background-position: center;
          }
        }
        .feedTitle {
          margin-top: 10px;
          p {
            margin: 0;
          }
        }
        .feedExcerpt {
          p {
            margin: 0;
          }
        }
      }
      .feedContainer:last-child {
        border: none;
      }
      .radialChart {
        height: 170px;
        margin-top: 26px;
        position: relative;
        .totalCount {
          margin: 0;
          position: absolute;
          text-align: center;
          left: 0;
          right: 0;
          bottom: calc(170px / 2 - 13px);
          h4,
          p {
            margin: 0;
          }
          p {
            color: #2f2f2f;
            line-height: 0.5;
          }
          .entryWrapper {
            width: 40%;
            margin: 0 auto;
            padding: 0 0 16px 0;
            border-bottom: 1px dashed #b1b1b1;
          }
          .entryWrapper:last-child {
            border: none;
            padding: 8.5px 0 0 0;
          }
        }
      }
      .verticalChart {
        margin-top: 10px;
        position: relative;
        text-align: right;
        .verticalBar {
          position: relative;
          margin-bottom: 13px;
          overflow: hidden;
        }
        .barBackground {
          background-color: #e2e2e2;
          border-radius: 3px;
          width: 100%;
          height: 5px;
          position: absolute;
          z-index: 0;
          bottom: 0;
        }
        .bar {
          height: 5px;
          border-radius: 3px;
          z-index: 1;
          position: relative;
          animation: 1s anim-lightspeed-in ease forwards;
        }
        .barLabel {
          float: left;
          font-size: 12px;
        }
        .barValue {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }
    }
    .reportCard.last {
      margin: 0;
    }
    .switch {
      position: absolute;
      display: inline-block;
      width: 25px;
      height: 15px;
      top: 15px;
      right: 15px;
      z-index: 1;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border: 1px solid #fff;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 9px;
      width: 9px;
      left: 2px;
      bottom: 2px;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }
    /* Rounded sliders */
    .slider.round {
      border-radius: 9px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
  .Surveys {
    @extend .Users;
    .survey-info {
      .name {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        span {
          font-weight: 300;
          font-size: 12px;
        }
      }
      hr {
        background: transparent;
        border-top: 1px solid #e2e2e2;
        border-left: none;
        border-right: none;
        border-bottom: none;
      }
      .surveyLine {
        margin-top: 15px;
        .surveyLineTitle {
          font-size: 12px;
          font-weight: bold;
        }
        .surveyLineValue {
          font-size: 12px;
          margin-top: 5px;
          min-height: 30px;
          .imageContainer {
            position: relative;
            margin-right: 5px;
            text-align: center;
            width: 30px;
            height: 30px;
            float: left;
            img {
              height: 18px;
            }
            img[alt="0"] {
              height: 10px;
            }
          }
        }
      }
      .surveyLine:first-child {
        margin-top: 0;
        margin-bottom: 18px;
      }
    }
  }
  .AddSurvey {
    @extend .Users;
    .stepContainer {
      width: calc(100% - 40px);
      margin-bottom: 100px;
      //min-height: 100vh;
      //min-height: -webkit-fill-available;
      //height: calc(100vw);
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #e2e2e2;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .upsert-one-element {
        overflow-y: auto;
        padding: 5px 5px 20px 5px;
      }
      .navigation {
        height: 30px;
        margin-top: auto;
        align-self: flex-start;
        width: 100%;
        .navArrow {
          font-size: 14px;
          color: #a9263d;
          cursor: pointer;
        }
        .navArrow:hover {
          color: #731a1a;
        }
        .back {
          float: left;
        }
        .next {
          float: right;
        }
      }
      .stepTitle {
        text-align: center;
        color: #2f2f2f;
        font-size: 21px;
        font-weight: bold;
      }
      .stepTitle.success {
        font-size: 20px;
        color: #2f2f2f;
        font-weight: normal;
        margin-top: 21px;
      }
      .logAnother {
        border-radius: 10px;
        width: 100%;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        margin-top: 18.5px;
        cursor: pointer;
      }
      .returnLink {
        font-size: 12px;
        text-align: center;
        margin-top: 20px;
        button {
          color: #a9263d;
          font-weight: bold;
          appearance: none;
          border: none;
          cursor: pointer;
        }
        button:hover {
          opacity: 0.7;
        }
      }
      .stepName {
        text-align: center;
        font-size: 14px;
        color: #2f2f2f;
      }
      .branchList {
        margin-top: 17px;
        height: calc(100% - 60px);
        margin-bottom: 15px;
        overflow: hidden;
        .groupContainer {
          overflow-y: auto;
          overflow-x: hidden;
          height: 185px;
        }
        .groupContainer.surveyField {
          height: 100%;
          padding-top: 10px;
        }
        .form-control {
          margin-bottom: 20px;
        }
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .branch-group {
          margin: 0px;
        }
        .checkmark {
          position: absolute;
          top: 0;
          right: 0;
          height: 15px;
          width: 15px;
          border-radius: 5px;
          background-color: #eee;
        }
        .checkmark:after {
          right: 3px;
          top: 4px;
          width: 7px;
          height: 3px;
          border: solid white;
          border-width: 0 0 2px 2px;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          content: "";
          position: absolute;
          display: none;
        }
        .selectSurvey {
          display: block;
          text-align: center;
          position: relative;
          box-sizing: border-box;
          padding: 20px;
          margin-left: auto;
          margin-right: auto;
          cursor: pointer;
          .checkmark {
            background-color: transparent;
            border-radius: 50%;
            top: -5.5px;
            right: -5.5px;
            z-index: 1;
          }
          .selectName {
            text-transform: capitalize;
            font-size: 14px;
          }
          .borderCheck {
            width: 100%;
            height: 100%;
            border: 1px solid #e2e2e2;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.5;
            background-color: #fff;
            transition: 0.2 all ease;
            box-sizing: border-box;
          }
        }
        .selectSurvey.fill-2 {
          width: calc(50% - 20px);
          padding: 20px 20px;
          margin-bottom: 20px;
          display: inline-block;
          margin: 0 10px;
        }
        .selectSurvey.fill-4 {
          width: calc(50% - 10px);
          padding: 10px;
          margin: 5px;
          display: inline-block;
          vertical-align: top;
        }
        @media only screen and (max-width: 400px) {
          .selectSurvey.fill-4:first-of-type {
            min-height: 100px;
          }
        }
        .selectSurvey.fill-5 {
          width: calc(50% - 10px);
          padding: 10px;
          margin: 5px;
          display: inline-block;
          vertical-align: top;
        }
        .selectSurvey.satisfaction {
          width: calc(20% - 10px);
          padding: 5px 0 0 0;
          margin-bottom: 10px;
          .selectName {
            display: none;
          }
        }
        @media only screen and (max-width: 320px) {
          .imageSurvey {
            width: 25px;
            height: 25px;
          }
        }
        .selectSurvey:hover input ~ .borderCheck,
        .selectSurvey input:checked ~ .borderCheck {
          border: 2px solid #42aee3;
          background-color: transparent;
          opacity: 1;
        }
        .selectSurvey input:checked ~ .checkmark {
          background-color: #2196f3;
        }
        /* Show the checkmark when checked */
        .selectSurvey input:checked ~ .checkmark:after {
          display: block;
        }
        .selectSurvey:last-child {
          margin-bottom: 0;
        }
        ul {
          list-style: none;
          padding-left: 0;
          .container {
            display: block;
            position: relative;
            padding-right: 35px;
            margin-bottom: 17px;
            cursor: pointer;
            font-size: 14px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            .activityIcon {
              width: 18px;
              margin-right: 16px;
              position: relative;
              bottom: -4px;
            }
          }
          .container:hover input ~ .checkmark {
            background-color: #ccc;
          }
          .container input:checked ~ .checkmark {
            background-color: #2196f3;
          }
          /* Show the checkmark when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }
        }
      }
    }
  }
}

.loaderContainer {
  width: 100%;
  position: relative;
  .loaderRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .loaderDiv {
      flex: 1 0 15%;
      margin: 5px;
    }
    .three-fourth {
      flex-grow: 20;
    }
  }
  .circleLoad {
    @extend .animated-background;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .textLoad {
    @extend .animated-background;
    height: 7px;
    margin-bottom: 6px;
  }
  .half {
    width: 50%;
  }
  .quarter {
    width: 25%;
  }
  .big {
    height: 12px;
  }
  .bigMargin {
    margin-bottom: 15px;
  }
  .hide {
    display: none;
  }
  .radialCircle {
    @extend .animated-background;
    border-radius: 50%;
    width: 154px;
    height: 154px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .radialCircle:before {
    content: "";
    background: #fff;
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    display: block;
    top: 17px;
    position: relative;
  }
  .resultBar {
    width: 100%;
    display: flex;
    margin-bottom: 20.5px;
    .barPlaceholder {
      @extend .animated-background;
      height: 42px;
      width: 100%;
      border-radius: 5px;
      margin-left: 10px;
    }
    .barPlaceholder:first-child {
      margin-left: 0;
    }
  }
  .resultChart {
    width: 100%;
    display: flex;
    .chartPlaceholder {
      @extend .animated-background;
      height: 150px;
      width: 100%;
      border-radius: 5px;
      margin-left: 13px;
    }
    .chartPlaceholder:first-child {
      margin-left: 0;
    }
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f2f2f2;
  background: linear-gradient(to right, #eaeaea 8%, #f2f2f2 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.react-datepicker__current-month,
.react-datepicker__day,
.react-datepicker__day-name {
  text-align: center !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input,
.react-datepicker-ignore-onclickoutside {
  width: 0px;
  height: 0px;
  padding: 0;
}

.Workplans,
.AddWorkplan {
  .field {
    margin-top: 5px;
    input {
      height: 30px;
      text-align: center;
      padding-left: 0;
      width: calc(100% - 111px) !important;
    }
    input.occurrence {
      width: calc(100% - 168px) !important;
    }
  }
  .user-logo {
    margin: 0;
    margin-left: 10px;
  }
  .arrow.user {
    margin-left: 10px;
  }
  .more {
    font-size: 13px;
  }
  .age {
    width: 14.5%;
  }
  .age:not(:last-of-type) {
    margin-right: 3px !important;
  }
  .gender {
    width: 15.5%;
  }
  .assign {
    margin-left: 8px;
  }
}

.select-div {
  .normal-margin {
    height: calc(100vh - 240px);
    overflow-x: auto;
    overflow-x: hidden;
  }
}

img.feedThumbContainer {
  width: 100%;
}

.mobile-goodnews {
  .manage-div {
    margin-bottom: 100px;
  }
  .manage-list {
    background: #f8f9fb;
  }
  .list-div {
    background: #ffffff;
    margin-bottom: 20px;
    padding: 20px;
  }
  .feedContent,
  .feedTitle {
    margin-top: 8px;
  }
  p {
    font-size: 12px;
  }
}

.dot-black {
  position: absolute;
  top: 25px;
  right: 20px;
}

.edit-box {
  height: 38px;
  width: 77px;
  z-index: 20;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #2f2f2f;
  border-radius: 5px;
  border: 0px;
  opacity: 1;
  right: 30px;
  top: 40px;
  padding: 20px;
  p {
    margin-bottom: 6px;
  }
}

.logout {
  top: 20px;
  right: 20px;
  color: #a9263d;
}

.desktop {
  top: 20px;
  right: 100px;
  color: #a9263d;
}

.menu {
  z-index: 1;
  width: 100%;
  background: #ffffff;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  .menu-item {
    width: 33.3%;
    text-align: center;
    height: 100%;
    img {
      margin-top: 13px;
    }
    p {
      font-size: 18px;
      color: #b1b1b1;
    }
  }
  .menu-item.active {
    p {
      color: #a9263d;
    }
  }
}

.title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
}

.branch-item {
  .branch-name {
    text-align: center;
    background: #f2f2f2;
    border-radius: 20px;
    height: 30px;
    margin-right: 10px;
    line-height: 30px;
    font-size: 14px;
    font-weight: Bold;
    color: #838080;
  }
}

.branch-item.active .branch-name {
  background: #a9263d;
  color: #ffffff;
}

.mobile-activities {
  h1 {
    margin: 0;
    font-size: 21px;
    font-weight: bold;
    margin-top: 30px;
  }
  p {
    font-size: 18px;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .list-div {
    padding: 20px;
  }
  .user-logo {
    height: 40px !important;
    width: 40px !important;
    padding: 0 !important;
    line-height: 40px;
  }
}

.slick-slider {
  margin-bottom: 20px;
}

.slick-slide {
  min-width: 126px;
  .branch-name {
    padding: 0 10px;
  }
}

.plus-button {
  position: fixed;
  background: #a9263d;
  box-shadow: 0px 0px 10px #e2e2e2;
  border-radius: 50%;
  font-size: 40px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  color: #ffffff;
  text-align: center;
  right: 20px;
  bottom: 120px;
}

.AddSession {
  .name.center {
    font-size: 21px;
    span {
      font-size: 14px;
    }
  }
  .user-logo {
    height: 132px !important;
    width: 132px !important;
    padding: 0 !important;
    line-height: 132px;
    font-size: 60px !important;
  }
}

.user-logo.small {
  margin: 0 !important;
  height: 20px !important;
  width: 20px !important;
  line-height: 20px;
  font-size: 12px !important;
  margin-right: 10px !important;
}

.placeholder {
  height: 100px;
}

.dots-span {
  font-size: 36px !important;
}
